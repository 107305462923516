import { useTranslation } from "gatsby-plugin-react-i18next";
import * as Yup from "yup";

import { CountryCode } from "../../../settings/countries";
import { getPhoneNumberLength } from "../../../utils/locale-configuration-utils";
import { FormikConfig } from "../../utils/formik-types";

export interface PhoneNumberFormValues {
  countryCode: string;
  phone: string;
}

export interface UsePhoneNumber extends FormikConfig<PhoneNumberFormValues> {
  phoneNumberLength: Record<string, number>;
}

export const usePhoneNumber = (
  defaultCountryCode: string,
  country: CountryCode
): UsePhoneNumber => {
  const { t } = useTranslation();
  const phoneNumberLength = getPhoneNumberLength(country);

  const initialValues: PhoneNumberFormValues = {
    countryCode: defaultCountryCode,
    phone: "",
  };

  const validationSchema: Yup.ObjectSchema<{
    countryCode: Yup.StringSchema;
    phone: Yup.StringSchema;
  }> = Yup.object({
    countryCode: Yup.string().trim().required(t("common.validation.required")),
    phone: Yup.string()
      .min(phoneNumberLength.min, t("common.validation.min", { minLength: phoneNumberLength.min }))
      .max(phoneNumberLength.max, t("common.validation.max", { maxLength: phoneNumberLength.max }))
      .trim()
      .required(t("common.validation.required")),
  });

  return { initialValues, validationSchema, phoneNumberLength };
};
