import BackgroundShape from "../../../../../atoms/background-shape/BackgroundShape";
import * as styles from "./BackgroundShapeLoader.module.scss";

interface BackgroundShapeProps {
  title?: string;
}

const BackgroundShapeLoader = ({ title }: BackgroundShapeProps): JSX.Element => {
  return (
    <div className={styles.backgroundShapeLoader}>
      {title && <span className={styles.title}>{title}</span>}
      <BackgroundShape adoptionClassName={styles.backgroundShape} hasAnimatedBackground={true} />
    </div>
  );
};

export default BackgroundShapeLoader;
