import { FormikHelpers } from "formik";
import React, { Dispatch, SetStateAction, useCallback } from "react";

import {
  PhoneNumberFormValues,
  UsePhoneNumber,
  usePhoneNumber,
} from "../../../../core/domain/hooks/usePhoneNumber";
import useOnce from "../../../../core/utils/hooks/useOnce";
import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../settings/countries";
import { Events, track } from "../../../../utils/analytics";

interface HandleFieldChange {
  (
    fieldName: keyof PhoneNumberFormValues,
    validateField: (field: string) => void,
    setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
  ): (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface HandleSubmit {
  (values: PhoneNumberFormValues, formikHelpers: FormikHelpers<PhoneNumberFormValues>): void;
}

interface UseQuotingForm extends UsePhoneNumber {
  handleFocus: () => void;
  handleFieldChange: HandleFieldChange;
  handleSubmit: HandleSubmit;
  handleSkip: () => void;
}

export const useQuotingForm = (
  defaultCountryCode: string,
  country: CountryCode,
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>,
  setIsWaitingForUserInteraction: Dispatch<SetStateAction<boolean>>
): UseQuotingForm => {
  const { initialValues, validationSchema, phoneNumberLength } = usePhoneNumber(
    defaultCountryCode,
    country
  );
  const result = useResult();

  const handleStartTyping = useOnce(() => {
    track(Events.START_TYPING_ON_FIELD, {
      eventSender: "Quoting page form - Phone number",
    });
  });

  const handleFocus = useCallback(() => {
    setIsWaitingForUserInteraction(true);
  }, [setIsWaitingForUserInteraction]);

  const handleFieldChange = useCallback(
    (
        fieldName: keyof PhoneNumberFormValues,
        validateField: (field: string) => void,
        setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
      ) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        validateField(fieldName);
        setFieldValue(fieldName, e.target.value.replace(/\D/g, ""));

        if (fieldName === "phone" && value.length > 0) {
          handleStartTyping();
        }
      },
    []
  );

  const handleSkip = useCallback(() => {
    track(Events.CLICKED_CTA, {
      eventSender: "Quoting page form",
      action: "Skip form",
    });
    setIsFormSubmitted(true);
  }, [setIsFormSubmitted]);

  const handleSubmit = useCallback(
    (values: PhoneNumberFormValues, { setSubmitting }: FormikHelpers<PhoneNumberFormValues>) => {
      track(Events.PHONE_SUBMITTED, {
        eventSender: "Quoting page form",
        preQuotesPhoneNumber: `+${values.countryCode}${values.phone}`,
        petName: result?.pet_name,
      });
      setSubmitting(true);
      setIsFormSubmitted(true);
    },
    [result?.pet_name, setIsFormSubmitted]
  );

  return {
    initialValues,
    phoneNumberLength,
    validationSchema,
    handleFocus,
    handleFieldChange,
    handleSkip,
    handleSubmit,
  };
};
