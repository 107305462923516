import cn from "classnames";
import { Form, Formik } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { Dispatch, ReactElement, SetStateAction } from "react";

import Button, { ButtonVariantName } from "../../../../../atoms/button/Button";
import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import Badge from "../../../../../core/ui/atoms/badge/Badge";
import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import { CountryCode } from "../../../../../settings/countries";
import { useQuotingForm } from "../../../application/hooks/useQuotingForm";
import WhatsAppIcon from "../../images/common/whatsapp-decorated.svg";
import * as styles from "./QuotingForm.module.scss";

interface QuotingFormProps {
  adoptionClassName?: string;
  country: CountryCode;
  defaultCountryCode: string;
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>;
  setIsWaitingForUserInteraction: Dispatch<SetStateAction<boolean>>;
}

const QuotingForm = ({
  adoptionClassName,
  country,
  defaultCountryCode,
  setIsFormSubmitted,
  setIsWaitingForUserInteraction,
}: QuotingFormProps): ReactElement => {
  const { t } = useTranslation();
  const {
    initialValues,
    phoneNumberLength: { min: phoneMinLength, max: phoneMaxLength },
    validationSchema,
    handleFocus,
    handleSkip,
    handleFieldChange,
    handleSubmit,
  } = useQuotingForm(
    defaultCountryCode,
    country,
    setIsFormSubmitted,
    setIsWaitingForUserInteraction
  );

  return (
    <div className={cn(styles.quotingForm, adoptionClassName)}>
      <WhatsAppIcon className={styles.whatsAppIcon} alt={"WhatsApp Icon"} />
      <Badge text={t("pg_quoting.quoting_form.badge.optional")} adoptionClassName={styles.badge} />
      <SectionTitle
        adoptionClassName={styles.sectionTitle}
        title={t("pg_quoting.quoting_form.title")}
        subtitle={t("pg_quoting.quoting_form.subtitle")}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, isValid, setFieldValue, touched, validateField }) => {
          const showButtons = dirty || touched.phone;

          return (
            <Form className={styles.form}>
              <TextFormControl
                name="countryCode"
                label={t("pg_quoting.quoting_form.country_code.field.label")}
                type="tel"
                adoptionClassName={styles.countryCode}
                maxLength={4}
                onFocus={handleFocus}
                onChange={handleFieldChange("countryCode", validateField, setFieldValue)}
              />
              <TextFormControl
                name="phone"
                label={t("pg_quoting.quoting_form.phone.field.label")}
                minLength={phoneMinLength}
                maxLength={phoneMaxLength}
                onFocus={handleFocus}
                onChange={handleFieldChange("phone", validateField, setFieldValue)}
              />
              {showButtons && (
                <>
                  <Button
                    adoptionClassName={styles.submitButton}
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {t("common.cta.see_price")}
                  </Button>
                  <Button
                    adoptionClassName={styles.skipButton}
                    variantName={ButtonVariantName.secondary}
                    type="button"
                    onClick={handleSkip}
                  >
                    {t("common.cta.skip")}
                  </Button>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default React.memo(QuotingForm);
