import cn from "classnames";
import React, { ReactElement } from "react";

import * as styles from "./Badge.module.scss";

interface BadgeProps {
  text: string;
  adoptionClassName?: string;
}

const Badge = ({ text, adoptionClassName }: BadgeProps): ReactElement => {
  return <span className={cn(styles.badge, adoptionClassName)}>{text}</span>;
};

export default Badge;
