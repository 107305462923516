import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement } from "react";

import Loader from "../../../../../molecules/loader/Loader";
import PollingLoader from "../../../../../organisms/polling-loader/PollingLoader";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../../settings/countries";
import useQuoting from "../../../application/hooks/useQuoting";
import BackgroundShapeLoader from "../../molecules/background-shape-loader/BackgroundShapeLoader";
import QuotingForm from "../../organisms/quoting-form/QuotingForm";
import * as styles from "./Quoting.module.scss";

const Quoting = (): ReactElement => {
  const { t } = useTranslation();
  const result = useResult();
  const {
    nextUrl,
    isCountdownOver,
    setIsWaitingForUserInteraction,
    isFormSubmitted,
    setIsFormSubmitted,
    hasQuotingForm,
  } = useQuoting();

  const loaderTitle = t("pg_quoting.loader.title", { petName: result?.pet_name });

  return (
    <>
      {nextUrl && (
        <div className={styles.quotingWrapper}>
          <PollingLoader
            nextUrl={nextUrl}
            statusesCallbacks={{
              created: ({ waitAndRetry }) => waitAndRetry(),
            }}
            {...(hasQuotingForm
              ? {
                  isWaitingToNavigate: !isCountdownOver && !isFormSubmitted,
                }
              : { timeoutToNavigate: 3500 })}
          >
            {hasQuotingForm ? (
              <BackgroundShapeLoader title={loaderTitle} />
            ) : (
              <Loader title={loaderTitle} />
            )}
          </PollingLoader>
          {hasQuotingForm && (
            <QuotingForm
              adoptionClassName={styles.quotingForm}
              country={result?.country ?? CountryCode.ES}
              defaultCountryCode={result?.pet_parent_country_code ?? ""}
              setIsFormSubmitted={setIsFormSubmitted}
              setIsWaitingForUserInteraction={setIsWaitingForUserInteraction}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Quoting;
